import shopifyGenericDefaults from '../shopify-generic/config.js';

window.Convermax.calcProductEntries = (item) => {
  if (item.compare_at_price > item.price && item.stock.includes('Available')) {
    return Math.floor(item.compare_at_price) * window.Convermax.sweepstakes_multiplier;
  }

  return Math.floor(item.price) * window.Convermax.sweepstakes_multiplier;
};

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  collapsibleAnimationTime: 0,
};
